import React from "react"
import SessionalRecordAttributeCapture from "../components/SessionalRecordAttributeCapture"
import FontAwesomeIcon from "../components/FontAwesomeIcon"
//import SessionalRecordsToCompleteTitleLine from "../components/SessionalRecordsToCompleteTitleLine"

const attributes = [{ title: "Agency", description: "Motivational and goal orientation." },
{ title: "Empathy", description: "Emotional intelligence." },
{ title: "Reflection", description: "Ability to plan, and put aspirations into action." },
{ title: "Productivity ", description: "Social emotional competence - resilience." },
{ title: "Self awareness", description: "Perceptions of self - now do you identify yourself and strengths and weaknesses and plan to improve them." },
{ title: "Creativity", description: "Exploration, engagement with new things." },
{ title: "Academic progress", description: "Averages on grade levels being worked at." }
];


const SessionalRecordDataCapture = (props) => {
  return <>
    <div className="col-xl-12 col-md-12 mb-4">
  
    

    <h3 className="small font-weight-bold"> <FontAwesomeIcon icon="address-card"/> {props.attendee.name}

     
    </h3>
    
              {/* <div className="card border-left-primary shadow h-100 py-2">
        <div className="card-body">
          <div className="row no-gutters align-items-center">
            <div className="col mr-2">
              <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">{props.caption}</div>
              <div className="h5 mb-0 font-weight-bold text-gray-800">{props.count}</div> */}
              {attributes.map((attributes,index)=>
                        <SessionalRecordAttributeCapture key={index} attributes={attributes} />
              )}
                    

          {/* </div> */}
    
        {/* </div>
      </div>
    </div>*/}
  </div> 
  </>
}

export default  SessionalRecordDataCapture