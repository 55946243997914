import React from "react"
import SessionalRecordDataCapture from "../components/SessionalRecordDataCapture"
import Sidebar from "../components/Sidebar"
import SessionalRecordsToCompleteTitleLine from "../components/SessionalRecordsToCompleteTitleLine"
//import ProjectEnrollment from "../components/ProjectEnrollment"
import TopBar from "../components/TopBar"
import Footer from "../components/Footer"

const inbox = { count: 3 }
//function logme(obj){console.log(obj.location.state)
//return obj}
const SessionalRecord = (props) => {
  //logme(props)
  const nullSession = { name: "Oops something went wrong here", date: "01-Jan-1901" }
  const nullAttendee = { name: "Oops sommthing went wrong here" }
  let session = nullSession;
  if (props.location !== undefined && props.location.state!== undefined) {
    session = props.location.state.session
  }
  let attendee = nullAttendee;
  if (props.location.state) {
    attendee = props.location.state.attendee
  }
  return (
    <>
      <div id="wrapper">
        <Sidebar gorole="practitioner" />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TopBar name="Pedro Perez" gorole="Practitioner" inbox={inbox} caption="Sessional Record" />
            <div className="container-fluid">
              <SessionalRecordsToCompleteTitleLine id="SessionalRecordsToCompleteTitleLine" session={session} />
              <SessionalRecordDataCapture session={session} attendee={attendee} />              
            </div>
          </div>
          <Footer/>
        </div>
      </div>

      <script src="./vendor/dave/test.js"></script>
      {/* Bootstrap core JavaScript*/}
      <script src="./vendor/jquery/jquery.min.js"></script>
      <script src="./vendor/bootstrap/js/bootstrap.bundle.min.js"></script>

      {/* Core plugin JavaScript*/}
      <script src="./vendor/jquery-easing/jquery.easing.min.js"></script>

      {/* Custom scripts for all pages*/}
      <script src="./js/sb-admin-2.min.js"></script>

      {/* Page level plugins */}
      <script src="./vendor/chart.js/Chart.min.js"></script>

      {/* Page level custom scripts */}
      <script src="./js/demo/chart-area-demo.js"></script>
      <script src="./js/demo/chart-pie-demo.js"></script>
    </>

  )
}
export default SessionalRecord
