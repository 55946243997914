import React from "react"
// import { Link } from "gatsby"
// import FontAwesomeIcon from "../components/FontAwesomeIcon"
// import SessionalRecordDataCaptureNumber from "../components/SessionalRecordDataCaptureNumber"

export default (props) => {
  return <>

  {/* <SessionalRecordsToCompleteTitleLine session={props.session}/>

   
    {props.session.attendees.map((attendee, index) => {
      return (
        <p key={index}>
          <Link to="/sessional-record" state={{ attendee: attendee , session: props.session }}>
            {props.session.name}
            <i className={`fas fa-${props.icon}`} style={{ paddingRight: "2px" }}></i>{attendee.name}
          </Link></p>
      )
    })} */}
    
    {/* <div className="card border-left-primary shadow h-100 py-2"> */}
    <div style={{border:"1px solid gray", borderRadius: "12px", padding:"3px"}} className=" ">
    <h4 style={{paddingBottom:"-4px"}}>{props.attributes.title}</h4>
    <p className="small"><i>{props.attributes.description}</i></p>
    <h5>Your assessment for this session:</h5>
 <p>
                  {/* <SessionalRecordDataCaptureNumber number="1" title={props.attributes.title}/>
                  <SessionalRecordDataCaptureNumber number="2" title={props.attributes.title}/>
                  <SessionalRecordDataCaptureNumber number="3" title={props.attributes.title}/>
                  <SessionalRecordDataCaptureNumber number="4" title={props.attributes.title}/>
                  <SessionalRecordDataCaptureNumber number="5" title={props.attributes.title}/> */}
                    {/* <input type="radio" name="score" value="1"> text="one"></input> */}
                    {/* <input type="radio" name="score" value="2"> 2</input>
                    <input type="radio" name="score" value="3"> 3</input>
                    <input type="radio" name="score" value="4"> 4</input>
                    <input type="radio" name="score" value="5"> 5</input> */}
                  </p>
                  <PizzaSizePicker key="pz" min="1" max="5" title={props.attributes.title}/>
                  <h5>Your notes for this session:</h5>
                  <textarea style={{width:"100%"}}/>
    </div>


  </>
}

//https://magnusbenoni.com/radio-buttons-react/
class PizzaSizePicker extends React.Component {
  // buttonStyle={
  //   backgroundColor: "#4CAF50",
  //   border: "none",
  //   color: "white",
  //   padding: "20px",
  //   textAlign: "center",
  //   textDecoration: "none",
  //   display: "inline-block",
  //   fontSize: "16px",
  //   margin: "4px 2px",
  // }

  sel={
  border: "none",
  color: "white",
  padding: "20px",
  textAlign: "center",
  textDecoration: "none",
  display: "inline-block",
  fontSize: "16px",
  margin: "4px 2px",
  backgroundColor: "#4CAF50",
borderRadius:"50%"}

  unsel={
  border: "none",
  color: "white",
  padding: "20px",
  textAlign: "center",
  textDecoration: "none",
  display: "inline-block",
  fontSize: "16px",
  margin: "4px 2px",
  backgroundColor:  "#aaa",
  borderRadius:"50%"}
  constructor(props) {
    super();
    this.state = {
      size: 0
    };
    this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);

  this.range=[1,2,3,4,5];

  }
  render() {
    return (
      <>
      
      {this.range.map((elem,index)=>{return (
        
        <button style={this.state.size===elem? this.sel:this.unsel} 
          key={index} onClick={(event) => this.handleChangeToValue(elem,event)}>{elem}
        </button> 
      )})}

      
      </>
    );
  }
  handleChangeToValue(value, event) {
  
   this.setState({      
      size: value
    });

  }
  handleChange(event) {
    this.setState({
      size: event.target.value
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    
    alert(`You chose the ${this.state.size} pizza.`);
  }
}